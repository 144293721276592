<template>
	<div class="d-flex flex-column flex-root" v-if="isAuthenticated">
		<!-- <div class="d-flex flex-row flex-column-fluid page"> -->
		<div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
			<div id="kt_header" ref="kt_header" class="header">
				<div class="row container-fluid d-flex align-items-center justify-content-between ml-1">
					<div>
						<router-link :to="{ path: '/' }">
							<img alt="Logo" :src="layoutConfig('self.logo.default')" class="logo-default max-h-50px" />
						</router-link>
					</div>
					<div>
						<KTQuickUser></KTQuickUser>
					</div>
				</div>
			</div>
			<div id="kt_content" class="content d-flex flex-column flex-column-fluid">
				<transition name="fade-in-up">
					<router-view />
				</transition>
			</div>
			<KTFooter></KTFooter>
		</div>
		<!-- </div> -->
		<KTScrollTop></KTScrollTop>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import MinimalHeader from '@/view/layout/header/MinimalHeader.vue';
import KTQuickUser from '@/view/layout/extras/offcanvas/QuickUser.vue';
import KTHeaderMobile from '@/view/layout/header/HeaderMobile.vue';
import KTFooter from '@/view/layout/footer/Footer.vue';
import HtmlClass from '@/core/services/htmlclass.service';
import KTScrollTop from '@/view/layout/extras/ScrollTop';
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from '@/core/services/store/htmlclass.module.js';

export default {
	name: 'Layout',
	components: {
		MinimalHeader,
		KTQuickUser,
		KTHeaderMobile,
		KTFooter,
		KTScrollTop,
	},
	beforeMount() {
		// show page loading
		this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

		// initialize html element classes
		HtmlClass.init(this.layoutConfig());
	},
	mounted() {
		this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
	},
	methods: {},
	computed: {
		...mapGetters(['isAuthenticated', 'layoutConfig']),

		loaderLogo() {
			return process.env.BASE_URL + this.layoutConfig('loader.logo');
		},

		asideEnabled() {
			return !!this.layoutConfig('aside.self.display');
		},
		toolbarDisplay() {
			// return !!this.layoutConfig("toolbar.display");
			return true;
		},
	},
};
</script>

<style scoped>
#kt_content.content {
	padding: 0;
}
#kt_header.header {
	box-shadow: none;
	/* background: #24292f; */
	border-bottom: 1px solid hsla(210, 18%, 87%, 1);
}
</style>
